@import '../../styles/variables.scss';

.component {
    position: absolute;
    background: transparent;
    bottom: 8px;
    right: 6px;
    height: 20px;
}

.arrows {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    :global {
        .MuiIconButton-root {
            padding: 0;

            svg {
                width: 12px;
                height: 12px;
                fill: $text-color-black;
            }

            &:hover,
            &:focus {
                background-color: transparent;
            }
        }
    }
}

.drop_down {
    transform: scale(-1, -1);
}

.disabled_button {
    :global {
        .MuiIconButton-root {
            opacity: 0.5;
        }
    }
}

.component_with_helper_text {
    bottom: 22px;
}
